import React from "react"
import Layout from "../../components/layout/layout"
import { Link } from "gatsby"
export default class Contact extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <Layout>
        <div className="">
          <h3>Third-party Settlement</h3>
          <p>
            All current and previous Bondholders are entitled to participate in
            the Settlement. Current Bondholders do not need to do anything to
            participate in the Settlement. Previous Bondholders must timely and
            properly submit a valid Claim Form to participate in the Settlement.
            Deadline to Object to Claims Report: February 18, 2019.
          </p>

          <p>
            Order Approving Claims Report
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2020/03/Order-Approving-Claims-Report.pdf"
            >
              Docket 394 - Order Approving Claims Report
            </Link>
          </p>

          <p>
            Motion to Approve Claims
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2020/02/Motion-to-Approve-Claims-Report.pdf"
            >
              Docket Number 386 - Motion to Approve Claims
            </Link>
          </p>
          <p>
            Order Granting Final Approval of Settlement
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2019/12/Dkt.-377-ORDER-GRANTING-FINAL-APPROVAL-OF-SETTLEMENT-AND-RELATED-RELIEF-INCLUDING-A-FINAL-BAR-ORDER.pdf"
            >
              Docket Number 377 - Order Granting Final Approval of Settlement
            </Link>
          </p>
          <p>
            Order Rescheduling Final Approval of Settlement and Related Relief:
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2019/12/Dkt.-371-ORDER-RESCHEDULING-FINAL-APPROVAL-OF-SETTLEMENT-AND-RELATED-RELIEF-4825-6527-3262-v1.pdf"
            >
              Docket Number 371 - Order Rescheduling Final Approval
            </Link>
          </p>
          <p>
            Previous Bondholder claim form, due September 27, 2019:
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2019/06/SEC-Stand-Alone-Bondholder-Claim-Form.pdf"
            >
              SEC - Stand Alone Bondholder Claim Form
            </Link>
          </p>
          <p>
            Fully executed third-party settlement agreement:
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2019/07/SEC-Settlement-Agreement-Executed.pdf"
            >
              SEC Settlement Agreement - Fully Executed
            </Link>
          </p>

          <p>
            Notice regarding third-party settlement:
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2019/06/SEC-Notice.pdf"
            >
              SEC - Notice
            </Link>
          </p>
          <p>
            Click here to view the Order Granting Preliminary Approval of the
            Settlement Motion, filed 6/27/2019:
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2019/06/Dkt.-No.-330-Order-Granting-Preliminary-Approval-of-Settlement-Motion.pdf"
            >
              Docket No. 330 - Order Granting Preliminary Approval of Settlement
              Motion
            </Link>
          </p>
          <p>
            Click here to view the settlement motion filed 5/24/2019:
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2019/05/SEC-Settlement-Mtn.pdf"
            >
              Settlement Motion 5.24.19
            </Link>
          </p>
        </div>
      </Layout>
    )
  }
}
